import React from 'react'

const PreLoader = () => (
  <div className="preloader">
    <div className="spinner">
      <div className="dot1 blue" />
      <div className="dot2" />
    </div>
  </div>
)

export default PreLoader
