import React from 'react'
import backImg1 from '../images/background/1-1.png'
import backImg2 from '../images/background/2-2.png'
import backImg3 from '../images/background/3-3.png'
import background7 from '../images/parallax/7.svg'
import background8 from '../images/parallax/8.svg'
import background9 from '../images/parallax/9.svg'
import icon2 from '../images/icons/2.png'
import icon5 from '../images/icons/5.png'
import icon7 from '../images/icons/7.png'
import slide1 from '../images/1.png'

const Features = () => (
  <section className="features my-5 py-5" id="features">
    <div className="rellax i-7" data-rellax-speed="-1">
      <img src={background7} alt="" />
    </div>
    <div className="rellax i-8" data-rellax-speed="3">
      <img src={background8} alt="" />
    </div>
    <div className="rellax i-9" data-rellax-speed="-2">
      <img src={background9} alt="" />
    </div>
    <div className="container">
      <div className="row justify-content-center justify-content-md-between justify-content-lg-center align-items-center">
        <div
          className="col-10 col-sm-7 col-md-3 col-lg-5 order-last order-md-first mt-4 mt-md-0 "
          id="features-parallax"
        >
          <img className="back" data-depth="0.4" src={backImg1} alt="" />
          <img className="back" data-depth="0.5" src={backImg2} alt="" />
          <img className="back" data-depth="0.6" src={backImg3} alt="" />
          <img className="front" data-depth="0.8" src={slide1} alt="" />
        </div>
        <div className="col-11 col-sm-10 col-md-6 col-lg-5">
          <div className="section-title text-center text-md-left">
            <h3>
              <span className="blue">Stop wasting your time...</span>
            </h3>
            <h1>
              Keep <span className="blue">Track</span> of how your Salesforce
              works
            </h1>
          </div>
          <div className="features-list">
            <ul>
              <li className="show-right">
                <img src={icon2} alt="" />
                <p>
                  <span>Map your Personas</span> - Mapping the types of users in
                  each application along with what permissions are assigned.
                </p>
              </li>
              <li className="show-right">
                <img src={icon5} alt="" />
                <p>
                  <span>Layout your goals</span> - Laying out your goals helps
                  everyone know what the project is all about.
                </p>
              </li>
              <li className="show-right">
                <img src={icon7} alt="" />
                <p>
                  <span>Document your features</span> - Documenting how your
                  features are built by mapping the Salesforce Metadata used to
                  implement it!
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Features
