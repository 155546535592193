import React from 'react'
import screenshot1 from '../images/parallax/4.svg'
import screenshot2 from '../images/parallax/5.svg'
import screenshot3 from '../images/parallax/6.svg'
import slide1 from '../images/1.png'
import slide2 from '../images/2.png'
import slide3 from '../images/3.png'

const Screenshots = () => (
  <section className="screenshots py-5" id="about">
    <div className="rellax i-4" data-rellax-speed="1">
      <img src={screenshot1} alt="" />
    </div>
    <div className="rellax i-5" data-rellax-speed="-3">
      <img src={screenshot2} alt="" />
    </div>
    <div className="rellax i-6" data-rellax-speed="-1">
      <img src={screenshot3} alt="" />
    </div>
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-md-8 col-lg-8 text-center">
          <div className="section-title">
            <h3>
              <span className="blue">What are you waiting for?</span>
            </h3>
            <h1>
              Take a <span className="blue">Peek</span> inside the Application
            </h1>
            <h3 className="mt-4">
              Tired of wondering how your Salesforce features and applications
              were implemented in your org? Save time, increase productivity,
              and cultivate a greater collaboration with a central repository
              that tracks how and why your applications were built!
            </h3>
          </div>
        </div>
      </div>

      <div className="row justify-content-center align-items-center text-center pb-4">
        <div className="col-11 col-sm-10 col-md-8">
          <p className="light mt-4 show-top">
            Available on the AppExchange Store:
          </p>
          <a
            href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000EtEv9UAF"
            className="download-button blue show-bottom"
          >
            <i className="fa fa-cloud" />
            <span>
              Get it<br /> today!
            </span>
          </a>
        </div>
      </div>

      <div className="row justify-content-center align-items-center my-5">
        <div className="col-12">
          <div className="screenshots-slider_v1">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img src={slide1} alt="slide 1" />
              </div>
              <div className="swiper-slide">
                <img src={slide2} alt="slide 1" />
              </div>
              <div className="swiper-slide">
                <img src={slide3} alt="slide 1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Screenshots
