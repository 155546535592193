import React from 'react'
import parallax10 from '../images/parallax/10.svg'
import parallax11 from '../images/parallax/11.svg'
import backImg1 from '../images/background/1-1.png'
import backImg2 from '../images/background/2-2.png'
import backImg3 from '../images/background/3-3.png'
import slide1 from '../images/1.png'

const Faq = () => (
  <section className="faq my-5 py-5" id="faq">
    <div className="rellax i-10" data-rellax-speed="-4">
      <img src={parallax10} alt="" />
    </div>
    <div className="rellax i-11" data-rellax-speed="2">
      <img src={parallax11} alt="" />
    </div>
    <div id="vertical-container" className="container">
      <div className="row justify-content-center justify-content-md-between justify-content-lg-center align-items-center">
        <div className="col-11 col-sm-10 col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
          <div className="section-title">
            <h3>
              <span className="blue">Still have questions?</span>
            </h3>
            <h1>
              Frequently Asked <span className="blue">Questions</span>, Get
              Detailed Answers
            </h1>
          </div>
          <div id="accordion" className="mt-5">
            <div className="card show-left">
              <div className="card-header blue" id="headingOne">
                <button
                  className="btn btn-link show"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  How is this different than any other tool?
                </button>
              </div>

              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordion"
                style={{ display: 'block' }}
              >
                <div className="card-body">
                  Catalio gives the ability to tie the Salesforce Metadata
                  directly to features. No more out of sync documentation.
                </div>
              </div>
            </div>
            <div className="card show-left">
              <div className="card-header blue" id="headingTwo">
                <button
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Can I change or add my own stuff?
                </button>
              </div>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordion"
              >
                <div className="card-body">
                  Yes! You can add fields, update page layouts, add automation
                  or change anything to make it work best for you and your
                  company.
                </div>
              </div>
            </div>
            <div className="card show-left">
              <div className="card-header blue" id="headingThree">
                <button
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Are there planned updates?
                </button>
              </div>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordion"
              >
                <div className="card-body">
                  Why yes we do. We plan on taking documentation to the next
                  level for the Salesforce Platform. Subscribe and stay up to
                  date to stay in the loop.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-10 col-sm-7 col-md-6 col-lg-5" id="faq-parallax">
          <img className="back" data-depth="0.4" src={backImg1} alt="" />
          <img className="back" data-depth="0.5" src={backImg2} alt="" />
          <img className="back" data-depth="0.6" src={backImg3} alt="" />
          <img className="front" data-depth="0.8" src={slide1} alt="" />
        </div>
      </div>
    </div>
  </section>
)

export default Faq
