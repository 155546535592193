import React from 'react'

const Subscribe = () => (
  <section className="subscribe py-5">
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-md-6 text-center">
          {/* <!-- subscribe title start --> */}
          <div className="section-title">
            <h2>
              Be informed of future <span className="blue">updates</span>
            </h2>
            <h3>Subscribe for updates and news.</h3>
          </div>
          {/* <!-- subscribe title start --> */}
        </div>
      </div>
      <div className="row justify-content-center align-items-center">
        <div className="col-12 text-center">
          {/* <!-- subscription form start --> */}
          <div className="news-form">
            <button
              type="submit"
              value="Subscribe"
              name="subscribe"
              className="btn button radius-btn blue mt-sm-0"
              onClick={() =>
                (window.location.href = 'https://blog.catalio.com/newsletter/')
              }
            >
              Subscribe
            </button>
          </div>
          {/* <!-- subscription form end --> */}
        </div>
      </div>
    </div>
  </section>
)

export default Subscribe
