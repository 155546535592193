import React from 'react'
import PreLoader from '../components/preloader.js'
import Banner from '../components/banner.js'
import Favorite from '../components/favorite.js'
import Screenshots from '../components/screenshots.js'
import Subscribe from '../components/subscribe.js'
import Features from '../components/features.js'
import Faq from '../components/faq.js'
import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <div className="wrapper">
      <PreLoader />
      <Banner />
      <Favorite />
      <Screenshots />
      <Subscribe />
      <Features />
      <Faq />
      <hr />
    </div>
  </Layout>
)

export default IndexPage
