import React from 'react'
import background1 from '../images/1.png'
import background2 from '../images/background/2.svg'
import background3 from '../images/background/3.svg'

const Banner = () => (
  <section className="home" id="home">
    <div className="container">
      <div className="row justify-content-center mt-5 pt-5">
        <div className="col-md-7 mt-5 text-center">
          <div className="home-description">
            <h1 className="title mb-4">
              <span>Sync Metadata</span>
              <br />
              Minimize <span className="blue">SLA times</span>
              <br />
              <span>Increase productivity</span>
            </h1>
            <p className="text">
              Cultivate a greater collaboration with a central repository that
              tracks how and why your applications were built!
            </p>
          </div>
          <a
            className="button radius-btn blue nav-link mt-4"
            href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000EtEv9UAF"
          >
            Download
          </a>
        </div>
      </div>
      <div className="row justify-content-center">
        <div
          className="col-md-12 d-none d-md-block mt-5 pt-3"
          id="home-parallax"
        >
          <img className="mx-auto" data-depth="0.4" src={background1} alt="" />
          <img className="back" data-depth="0.1" src={background2} alt="" />
          <img className="back-2" data-depth="0.2" src={background3} alt="" />
        </div>
      </div>
    </div>
  </section>
)

export default Banner
