import React from 'react'
import favBackground from '../images/parallax/1.svg'
import icon30 from '../images/icons/30.png'
import icon27 from '../images/icons/27.png'
import icon20 from '../images/icons/20.png'

const Favorite = () => (
  <section className="favorite my-5 pt-5">
    <div className="rellax i-1" data-rellax-speed="-4">
      <img src={favBackground} alt="Background" />
    </div>
    <div className="container">
      <div className="row favorite-content justify-content-center justify-content-md-start align-items-center">
        <div className="col-11 col-sm-9 col-md-6 col-lg-4">
          <div className="favorite-item show-bottom">
            <img src={icon30} alt="Easy To Customization" />
            <h3>Easy Customization</h3>
            <p>
              We provide a good starting point, but customize it to make it
              yours!
            </p>
          </div>
        </div>
        <div className="col-11 col-sm-9 col-md-6 col-lg-4">
          <div className="favorite-item show-bottom">
            <img src={icon27} alt="Detailed Documentation" />
            <h3>Detailed Documentation</h3>
            <p>
              Quickly search metadata is your org without having to be a
              developer!
            </p>
          </div>
        </div>
        <div className="col-11 col-sm-9 col-md-6 col-lg-4">
          <div className="favorite-item show-bottom">
            <img src={icon20} alt="24/7 Supporting" />
            <h3>Speed up Support</h3>
            <p>
              Stop guessing how your applications work and how they are
              implemented.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Favorite
